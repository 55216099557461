import React, { useEffect, useState } from 'react';
import axios from 'axios';

const MyComponent = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Using Axios for consistency
    axios.get('https://digitalkprojects.co.in/newtest/api/')
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
     {data.map((item) => (
  <p key={item.id}>
    name: {item.name}, email: {item.email}
  </p>
))}
    </div>
  );
};

export default MyComponent;